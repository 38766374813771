<template>
  <div class="login-page">

    <div class="login-wrap">
      <div class="row justify-content-center m-0">
        <div class="col-xl-6 col-lg-6 col-md-12 bg-color-6  ">
          <div class="fxt-header">
            <a href="#" class="fxt-logo"><img src="static/images/eluminous-pvt-ltd_icon.png" alt="" /></a>
          </div>
          <div class="login-box bg-white border-radius-10">
            <div class="login-title">
              <h2 class="text-primary">Change Password</h2>
              <p>Enter your new password, confirm and submit</p>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-group custom custom-icon custom_password">
                <div class="control is-expanded">
                  <Field  v-if="showPassword" type="text" name="old_password" v-model="form.old_password" class="form-control form-control-lg"
                    :class="{ 'is-invalid': errors.old_password }" placeholder="Old Password" />
                  <Field v-if="!showPassword" type="password" name="old_password" v-model="form.old_password" class="form-control form-control-lg"
                  :class="{ 'is-invalid': errors.old_password }" placeholder="Old Password" />
                  <div class="invalid-feedback">{{ errors.old_password }}</div>
                  <div v-if="old_password_err" class="invalid-feedback-custom">{{ old_password_err }}</div>
                </div>
                <div class="control icon_last">
                  <button class="button" type="button" @click="toggleShow"><span class="icon is-small is-right">
                    <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="input-group custom custom-icon custom_password">
                <div class="control is-expanded">
                  <Field  v-if="newShowPassword" type="text" name="new_password" v-model="form.new_password" class="form-control form-control-lg"
                    :class="{ 'is-invalid': errors.new_password }" placeholder="New Password" />
                  <Field v-if="!newShowPassword" type="password" name="new_password" v-model="form.new_password" class="form-control form-control-lg"
                  :class="{ 'is-invalid': errors.new_password }" placeholder="New Password" />
                  <div class="invalid-feedback">{{ errors.new_password }}</div>
                </div>
                <div class="control icon_last">
                  <button class="button" type="button" @click="toggleShowNewPass"><span class="icon is-small is-right">
                    <i class="fa" :class="{ 'fa-eye-slash': newShowPassword, 'fa-eye': !newShowPassword }"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="input-group custom custom-icon custom_password">
                <div class="control is-expanded">
                  <Field  v-if="showConfirmPassword" type="text" name="confirm_password" v-model="form.confirm_password" class="form-control form-control-lg"
                    :class="{ 'is-invalid': errors.confirm_password }" placeholder="Confirm New Password" />
                  <Field v-if="!showConfirmPassword" type="password" name="confirm_password" v-model="form.confirm_password" class="form-control form-control-lg"
                  :class="{ 'is-invalid': errors.confirm_password }" placeholder="Confirm New Password" />
                  <div class="invalid-feedback">{{ errors.confirm_password }}</div>
                </div>
                <div class="control icon_last">
                  <button class="button" type="button" @click="toggleShowConfirmPass"><span class="icon is-small is-right">
                    <i class="fa" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-5">
                  <div class="input-group mb-0">
                    <!--
                        use code for form submit
                                    <input class="btn btn-primary btn-lg btn-block" type="submit" value="Submit">
                                -->

                    <!-- <button
                      class="btn btn-primary btn-lg btn-block text-center"
                      type="submit"
                      value="Submit" >Submit</button> -->
                    <button type="submit" :disabled="isDisable" class="btn btn-primary btn-lg">Submit</button>
                  </div>
                </div>
              </div>

              <div class="inline_loader" v-if="isDisable">
                <img src="static/images/Spinner-1s-200px.gif" class="btn-loader">
              </div>
            </Form>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="hero-heading">
            <div class="headline">
              <h3>Welcome to Skill Upgradation Program</h3>
              <p>We are delighted to see you here. Get your way to upskill the technologies in the Digital World & Stand
                out from the Crowd</p>
            </div>
          </div>
          <div class="login-img">
            <img src="static/images/colleagues-working-project-discussing-details.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import userAPI from "../api/user";

export default {
  name: "change-password",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
        
        old_password: Yup.string()
        .min(6, "Old password must be at least 6 characters")
        .required("Old password is required"),
        new_password: Yup.string()
        .min(6, "New password must be at least 6 characters")
        .required("New password is required")
        .notOneOf([Yup.ref("old_password"), null], "Old and New password must not same"),
        confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm password is required"),
    });

    return {
      isDisable: false,
      schema,
      form: {
        new_password: "",
        confirm_password: ""
      },
      old_password_err:"",
      showPassword:false,
      newShowPassword:false,
      showConfirmPassword:false
    };
  },
  methods: {
    onSubmit() {
      userAPI.changePassword(this.form).then((response) => {
        this.isDisable = true
        if (response.status === "success") {
          this.isDisable = false
          this.$toast.show(response.message, {
            type: "success",
          });
          this.$router.push({ name: "updateUser" });
        } else {
          this.isDisable = false
          // this.$toast.show(response.message, {
          //   type: "error",
          // });
          this.old_password_err = "Old password doesn't match"
        }
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowNewPass(){
      this.newShowPassword = !this.newShowPassword;
    },
    toggleShowConfirmPass() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  }
};
</script>

<style>
body.header-white.sidebar-dark {
  padding-bottom: 0px;
}
</style>