<template>
	<div class="pre-loader" v-if="isLoading">
		<div class="pre-loader-box">
			<div class="loader-logo"><img src="../../public/static/images/Spinner-1s-200px.gif" alt="" height="100px"
					width="200px" /></div>
			<!-- <div class='loader-progress d-none' id="progress_div">
				<div class='bar' id='bar1'></div>
			</div>
			<div class='percent d-none' id='percent1'>0%</div>
			<div class="loading-text d-none">
				Loading...
			</div> -->
		</div>
		<!-- <loading :active="true" 
        :can-cancel="true"
		:color="'#1b00ff'" 
		:loader="'bars'"
        :is-full-page="true"></loading> -->
	</div>

	<AdminHeader />

	<RightSide />

	<LeftSide />

	<div class="mobile-menu-overlay"></div>

	<div class="main-container">
		<div class="pd-ltr-20">
			<router-view name="admin"></router-view>

		</div>
	</div>
	<AdminFooter />
</template>

<script>
import Vue from 'vue'
import AdminHeader from '../components/layout/Header.vue'
import RightSide from '../components/layout/RightSidebar.vue'
import LeftSide from '../components/layout/admin/LeftSidebar.vue'
import AdminFooter from '../components/layout/Footer.vue'
// import Loading from 'vue-loading-overlay';
//     import 'vue-loading-overlay/dist/vue-loading.css';
export default {
	name: 'adminPanel',
	components: { AdminHeader, RightSide, LeftSide, AdminFooter },
	data() {
		return {
			isLoading: true,
			fullPage: true,
		}
	},
	mounted() {
		setTimeout(() => {
			this.isLoading = false;
		}, 2000);
	},
}
</script>

