<template>
  <div class="login-page">

    <div class="login-wrap">
      <div class="row justify-content-center m-0">
        <div class="col-xl-6 col-lg-6 col-md-12 bg-color-6  ">
          <div class="fxt-header">
            <a href="#" class="fxt-logo"><img src="static/images/eluminous-pvt-ltd_icon.png" alt="" /></a>
            <div class="fxt-page-switcher">

              <router-link class="login-link" to="/login">
                <a href="#" class="switcher-text1 active">Login</a>
              </router-link>

              <router-link class="" to="/register">
                <a href="#" class="switcher-text1">Register</a>
              </router-link>
            </div>
          </div>
          <div class="login-box bg-white border-radius-10">
            <div class="login-title">
              <h2 class="text-primary">Reset Password</h2>
              <p>Enter your new password, confirm and submit</p>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-group custom custom-icon">
                <Field  v-if="showPassword" type="text" name="new_password" v-model="form.new_password"
                  class="form-control form-control-lg" :class="{ 'is-invalid': errors.new_password }"
                  placeholder="New Password" />
                  
                  <Field v-else type="password" name="new_password" v-model="form.new_password"
                  class="form-control form-control-lg" :class="{ 'is-invalid': errors.new_password }"
                  placeholder="New Password" />

                  <div class="control no_dott">
                    <button class="buton" @click="toggleShow"><span class="icon is-small is-right">
                      <img v-if="showPassword" src="https://kra.betaeserver.com/static/images/unlock.png" alt=""  />
                      <img v-else src="https://kra.betaeserver.com/static/images/lock.png" alt=""/>
                      </span>
                    </button>
                  </div>
                  
                  <!-- <img src="https://kra.betaeserver.com/static/images/lock.png" alt="" /> -->
                <div class="input-group-append custom">
                  <span class="input-group-text"><i class="icon-copy dw dw-user1"></i></span>
                </div>
                <div class="invalid-feedback">{{ errors.new_password }}</div>
              </div>
              <div class="input-group custom custom-icon">
                <Field v-if="showConfirmPassword" type="text" name="confirm_password" v-model="form.confirm_password"
                  class="form-control form-control-lg" :class="{ 'is-invalid': errors.confirm_password }"
                  placeholder="Confirm New Password" />

                  <Field v-else type="password" name="confirm_password" v-model="form.confirm_password"
                  class="form-control form-control-lg" :class="{ 'is-invalid': errors.confirm_password }"
                  placeholder="Confirm New Password" />

                  <div class="control no_dott">
                    <button class="buton" @click="toggleShowConfirmPass"><span class="icon is-small is-right">
                      <img v-if="showConfirmPassword" src="https://kra.betaeserver.com/static/images/unlock.png" alt=""  />
                      <img v-else src="https://kra.betaeserver.com/static/images/lock.png" alt=""/>
                      </span>
                    </button>
                  </div>
                  
                <div class="input-group-append custom">
                  <span class="input-group-text"><i class="dw dw-padlock1"></i></span>
                </div>
                <div class="invalid-feedback">{{ errors.confirm_password }}</div>
              </div>
              <div class="row pb-30">
              <!-- <div class="col-6">
                             <div class="custom-control custom-checkbox">
                                                     <input type="checkbox" class="custom-control-input" id="customCheck1">
                                                     <label class="custom-control-label" for="customCheck1">Remember</label>
                                                 </div> 
                </div>-->
                <div class="submit-button">
                  <div class="input-group mb-0">
                    <input class="btn btn-primary btn-lg btn-block" type="submit" value="submit" />
                  </div>


                </div>
              </div>

            </Form>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="hero-heading">
            <div class="headline">
              <h3>Welcome to Skill Upgradation Program</h3>
              <p>We are delighted to see you here. Get your way to upskill the technologies in the Digital World & Stand
                out from the Crowd</p>
            </div>
          </div>
          <div class="login-img">
            <img src="https://kra.betaeserver.com/static/images/colleagues-working-project-discussing-details.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import userAPI from "../api/user";

export default {
  name: "ResetPassword",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({

        new_password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
        confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm password is required"),

     
    });
    return {
      schema,
      form: {
        new_password: "",
        confirm_password: ""
      },
    };
  },
  methods: {
    onSubmit() {

      userAPI.resetPassword(this.form, this.$route.params.token).then((response) => {

        if (response.status === "success") {
          this.isDisable = false
          this.$toast.show(response.message, {
            type: "success",
          });
          this.$router.push({ name: "updateUser" });
        } else {
          this.isDisable = false
          this.$toast.show(response.message, {
            type: "error",
          });
        }
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirmPass() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  }
};
</script>
  
  