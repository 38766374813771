import userAPI from '../../api/user'

const state = {
  authUser: null,
  tokens: null,
  isAuthenticated:false
}

const actions = {
  
  updateUser ({commit}, userId) {
    console.log('data', userId)
    let promise = userAPI.getSingleUser(userId)
    
    promise.then((response) => {
      console.log('udated_data')
      console.log(response.data)
        if (response.status === "success") {
          console.log('udated_data')
          console.log(response.data)
            commit('SetAuthUser', window.btoa(JSON.stringify(response.data)))
        } 
       // todo: check refresh token logic
    //   commit('setStatus', localStorage.getItem('status'))
    }, () => {
      commit('setTokens', null)
    })
    return promise
  },
  login ({commit}, credentials) {
    let promise = userAPI.loginUser(credentials)
    promise.then((response) => {
        
        if (response.status === "success") {
            commit('setTokens', response.data.access_token)
            // commit('SetAuthUser', response.data)
            commit('SetAuthUser', window.btoa(JSON.stringify(response.data)))
            commit('Setauthenticated', true)
        } 
       // todo: check refresh token logic
    //   commit('setStatus', localStorage.getItem('status'))
    }, () => {
      commit('setTokens', null)
    })
    return promise
  },
  logout ({commit}) {
    if (localStorage.getItem('impersonate-token')) {
      localStorage.removeItem('impersonate-token')
    } else {
      commit('setTokens', null)
      commit('SetAuthUser', null)
      commit('Setauthenticated', false)
      // userAPI.logout()
    }
  },
 
}

const mutations = {
    setTokens (state, tokens) {
        state.tokens = tokens
    },
    SetAuthUser (state, authUser) {
        state.authUser = authUser
    },
    Setauthenticated (state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated
    },
}

const getters = {
    oauthTokens (state) {
        return state.tokens
    },
    currentUser (state) {
        return state.authUser
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
}



export default {
  state,
  getters,
  actions,
  mutations,
}
