<template>
  <div class="login-page reg-page register_scroll">
    <div class="login-wrap">
      <div class="row justify-content-center m-0">
        <div class="col-xl-6 col-lg-6 col-md-12 bg-color-6  ">
          <div class="fxt-header">
            <a href="#" class="fxt-logo"><img src="static/images/eluminous-pvt-ltd_icon.png" alt="" /></a>
            <div class="fxt-page-switcher">
              <router-link class="login-link" to="/login">
                <a href="#" class="switcher-text1 ">LogIn</a>
              </router-link>
              <router-link class="" to="/register">
                <a href="#" class="switcher-text1 active">Register</a>
              </router-link>
            </div>
          </div>
          <div class="login-box bg-white border-radius-10">
            <div class="login-title">
              <h2 class="text-primary">Register</h2>
              <p>Create an account free and enjoy it</p>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="form-wrap max-width-600 mx-auto">

                <!-- Upload image input-->
                <div class="custom-upload"> 
                  <div class="image-area mt-4" v-if="previewImage"><img id="imageResult" :src="previewImage" alt=""
                      class="rounded shadow-sm mx-auto d-block"></div>

                  <div class="image-area mt-4" v-else><img src="static/images/default-profile-icon.jpg" alt=""
                      class="rounded shadow-sm mx-auto d-block"></div>

                  <div class="input-group mb-3 px-0 py-2  bg-white shadow-sm">

                    <input type="file" name="profile_photo" class="form-control border-0" id="upload" ref="file"
                      accept="image/*" @change="selectImage" />
                    <label id="upload-label" for="upload" class="font-weight-light text-muted">Upload Profile picture</label>
                    <div class="input-group-append">
                      <label for="upload" class="btn btn-light m-0 rounded-pill px-4"> <i
                          class="fa fa-cloud-upload mr-2 text-muted"></i><small
                          class="text-uppercase font-weight-bold text-muted">Choose file</small></label>
                    </div>
                    <span v-if="profile_pic_name" class="img_des w-100">{{ profile_pic_name }}</span>
                  </div>
                  <div v-if="profile_pic_err" class="invalid-feedback-custom">{{ profile_pic_err }}</div>
                </div>
                <div class="input-group custom custom-icon">
                  <Field type="text" name="first_name" v-model="form.first_name" class="form-control"
                    placeholder="First name" :class="{ 'is-invalid': errors.first_name }" onkeypress="return /[a-z]/i.test(event.key)"/>
                  <img src="static/images/user.png" alt="" />
                  <div class="invalid-feedback">{{ errors.first_name }}</div>
                </div>
                <div class="input-group custom custom-icon">
                  <Field type="text" name="last_name" v-model="form.last_name" class="form-control"
                    placeholder="Last name" :class="{ 'is-invalid': errors.last_name }" onkeypress="return /[a-z]/i.test(event.key)"/>
                  <img src="static/images/user.png" alt="" />
                  <div class="invalid-feedback">{{ errors.last_name }}</div>
                </div>
                <div class="input-group custom custom-icon">
                  <Field type="email" name="email" v-model="form.email" class="form-control" placeholder="Email Address"
                    :class="{ 'is-invalid': errors.email }" />
                  <img src="static/images/email.png" alt="" />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <!-- <span v-show="errors.has('register.email')" class="help is-danger">{{ errors.first('register.email') }}</span> -->
                </div>
                <div class="input-group custom custom-icon custom_password">
                  <div class="control is-expanded">
                    <Field :type="showPassword ? 'text':'password'" name="password" v-model="form.password" class="form-control form-control-lg"
                      :class="{ 'is-invalid': errors.password }" placeholder="password" />
                    <!-- <Field v-if="!showPassword" type="password" name="password" v-model="form.password" class="form-control form-control-lg"
                    :class="{ 'is-invalid': errors.password }" placeholder="password" /> -->
                    <div class="invalid-feedback">{{ errors.password }}</div>
                  </div>
                  <div class="control icon_last">
                    <button class="button" type="button" @click="toggleShow"><span class="icon is-small is-right">
                      <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="input-group custom custom-icon custom_password">
                  <div class="control is-expanded">
                    <Field :type="showConfirmPassword ? 'text':'password'" name="password_confirmation" v-model="form.confirm_password" class="form-control form-control-lg"
                      :class="{ 'is-invalid': errors.password_confirmation }" placeholder="password" />
                    <!-- <Field v-if="!showConfirmPassword" type="password" name="password_confirmation" v-model="form.confirm_password" class="form-control form-control-lg"
                    :class="{ 'is-invalid': errors.password_confirmation }" placeholder="password" /> -->
                    <div class="invalid-feedback">{{ errors.password_confirmation }}</div>
                  </div>
                  <div class="control icon_last">
                    <button class="button" type="button" @click="toggleShowConfirmPass"><span class="icon is-small is-right">
                      <i class="fa" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <!-- <div class="input-group custom custom-icon">

                 
                  <Field v-if="showConfirmPassword" type="text" name="password_confirmation" v-model="form.confirm_password" class="form-control"
                    placeholder="Confirm Password" :class="{ 'is-invalid': errors.password_confirmation }"
                    data-vv-as="password" />

                    <Field v-else type="password" name="password_confirmation" v-model="form.confirm_password" class="form-control"
                    placeholder="Confirm Password" :class="{ 'is-invalid': errors.password_confirmation }"
                    data-vv-as="password" />

                  <div class="control no_dott" @click="toggleShowConfirmPass">
                    <button class="buton" ><span class="icon is-small is-right">
                      <img v-if="showConfirmPassword" src="static/images/unlock.png" alt=""  />
                      <img v-else src="static/images/lock.png" alt=""/>
                      </span>
                    </button>
                  </div>

                  <div class="invalid-feedback">
                    {{ errors.password_confirmation }}
                  </div>
                </div> -->
                <!-- <div class="input-group custom custom-icon">
                                  <div class="col-sm-12">
                                      <select class="custom-select col-12" v-model="form.designation_id">
                                          <option selected="">Choose...</option>
                                          <option value="1">SE</option>
                                          <option value="2">SEE</option>
                                          <option value="3">TL</option>
                                          <option value="4">M</option>
                                      </select>
                                  </div>
                              </div> -->
                    <div class="input-group custom custom-icon dropdown_custom_icon">
                        <Field name="role" as="select" class="custom-select col-12" :class="{ 'is-invalid': errors.role }"
                          v-model="form.role_id">
                        <option value="">Select Role</option>
                          <option v-for="role in roles" :value="role.id" v-bind:key="role.id">
                            {{ role.name }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">{{ errors.role }}</div>
                    </div>
                  
              

                <div class="input-group custom custom-icon dropdown_custom_icon">
                  <Field name="dep" as="select" class="custom-select col-12" :class="{ 'is-invalid': errors.dep }"
                    v-model="form.department_id">
                    <option value="">Select Department</option>
                    <option v-for="dep in departmentList" :value="dep.id" v-bind:key="dep.id">
                      {{ dep.name }}
                    </option>
                  </Field>
                  <div class="invalid-feedback">{{ errors.dep }}</div>
                </div>
                <div class="input-group custom custom-icon dropdown_custom_icon" v-if="form.department_id == 1">
                  <Field name="dep_team" as="select" class="custom-select col-12" :class="{ 'is-invalid': errors.dep_team }"
                    v-model="form.department_team_id">
                    <option value="">Select Department Team</option>
                    <option v-for="dep_team in departmentTeamList" :value="dep_team.id" v-bind:key="dep_team.id">
                      {{ dep_team.name }}
                    </option>
                  </Field>
                  <div class="invalid-feedback">{{ errors.dep_team }}</div>
                </div>
                <div class="register-btn">

                  <div class="input-group mb-0">
                    <button class="btn btn-primary btn-lg btn-block text-center" type="submit" value="Submit">
                      Submit
                    </button>

                    <div class="inline_loader" v-if="isDisable">
                    <img src="/static/images/Spinner-1s-200px.gif" class="btn-loader">
                  </div>
                  </div>

                  <div class="input-group mb-0">
                    <router-link class="btn btn-outline-primary btn-lg btn-block" to="/login">Cancel</router-link>

                  </div>
                </div>

              </div>
            </Form>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="hero-heading">
            <div class="headline">
              <h3>Welcome to Skill Upgradation Program</h3>
              <p>We are delighted to see you here. Get your way to upskill the technologies in the Digital World & Stand
                out from the Crowd</p>
            </div>
          </div>
          <div class="login-img">
            <img src="static/images/colleagues-working-project-discussing-details.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import userAPI from "../api/user";

export default {
  //   setup(_, context) {
  //     const first_name = ref("");
  //     const last_name = ref("");
  //     const email = ref("");
  //     const password = ref("");
  //     const confirm_password = ref("");
  //     const role_id = ref("");
  //     const designation_id = ref("");

  //     function register() {
  //       console.log('here', first_name.value)
  //     }

  //     return {
  //       first_name,
  //       last_name,
  //       email,
  //       password,
  //       confirm_password,
  //       role_id,
  //       designation_id
  //     };
  //   },
  components: {
    Form,
    Field,
  },
  data() {
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const schema = Yup.object().shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      role: Yup.string().required("Role is required"),
      profile_photo: Yup.mixed().test('format',
        'upload file', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
      dep: Yup.string().required("Department is required"),
      dep_team: Yup.string().when("dep", {
        is: (depValue) => depValue == 1,
        then: Yup.string().required("Department team is required"),
        otherwise: Yup.string(), 
      }),
    });
    return {
      schema,
      isDisable: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        role_id: "",
        profile_photo: "",
        department_team_id:''
        // designation_id:'',
      },
      roles: [],
      currentImage: '',
      previewImage: '',
      departmentList: [],
      file: null,
      profile_pic_err:'',
      profile_pic_name:'',
      showPassword:false,
      showConfirmPassword:false,
      departmentTeamList:[]
    };
  },
  methods: {
    onSubmit(values) {
      // alert('SUCCESS!! :-)\n\n' + JSON.stringify(values, null, 4));
      if(this.currentImage){
        if (this.file <= 1024) {
          this.isDisable = true
          let formData = new FormData();
          formData.append("first_name", this.form.first_name);
          formData.append("department_id", this.form.department_id);
          formData.append("last_name", this.form.last_name);
          formData.append("email", this.form.email);
          formData.append("password", this.form.password);
          formData.append("confirm_password", this.form.confirm_password);
          formData.append("role_id", this.form.role_id);
          formData.append("profile_photo", this.currentImage);
          formData.append("department_team_id",this.form.department_team_id);
          userAPI.registerUser(formData).then((response) => {

            if (response.status === "success") {
              this.isDisable = false
              this.$toast.show(response.message, {
                type: "success",
              });
              this.$router.push({ name: "login" });
            } else {
              this.isDisable = false
              this.$toast.show(response.message, {
                type: "error",
              });
            }
          });
        } else {
          this.$toast.show('File size should not exceed limit of 1MB', {
            type: "error",
          });
        }
      }else{
        this.profile_pic_err = 'Please upload profile picture'
      }
    },
    getRoles() {
      userAPI.roles().then((response) => {

        if (response.status === "success") {

          var data = response.data;
          data.forEach(element => {
            if (element.id !== 1) {
              this.roles.push(element)
            }

          });

        } else {
          this.$toast.show(response.message, {
            type: "error",
          });
        }
      });
    },
    selectImage() {console.log('images',this.$refs.file.files)
      let imagevalidate = ['image/jpg', 'image/jpeg', 'image/png'].includes(this.$refs.file.files.item(0).type)
      if (imagevalidate) {
        this.profile_pic_name = this.$refs.file.files.item(0).name
        this.profile_pic_err = ''
        this.currentImage = this.$refs.file.files.item(0);
        this.previewImage = URL.createObjectURL(this.currentImage);
        const fsize = this.$refs.file.files.item(0).size;
        this.file = Math.round((fsize / 1024));
        if (this.file > 1024) {
          this.$toast.show('File size should not exceed limit of 1MB', {
            type: "error",
          });
        }
      } else {
        this.profile_pic_err = 'Please select profile picture in format jpg,jpeg,png'
      }
    },
    getDepartment() {
      userAPI.getDepartmentList().then((response) => {

        if (response.status === "success") {
          this.departmentList = response.data;
        } else {
          this.$toast.show(response.message, {
            type: "error",
          });
        }
      });
    },
    getDepartmentTeam() {
      userAPI.getDepartmentTeamList().then((response) => {
        if (response.status === "success") {
          this.departmentTeamList = response.data;
        } else {
          this.departmentTeamList = [];
        }
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirmPass() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
  created() {
    this.getRoles();
    this.getDepartment();
    this.getDepartmentTeam();
  },
};
</script>
<style>
.invalid-feedback-custom{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
