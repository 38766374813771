<template>
  <AdminPanel v-if="!loginRoute && !forgotPasswordRoute && !registerRoute && !changepasswordRoute &&  isAuthenticated"/>
  <Login v-if="!isAuthenticated && !forgotPasswordRoute && !registerRoute && !changepasswordRoute && !resetPasswordRoute"/>
  <ForgotPassword v-if="!loginRoute && forgotPasswordRoute && !isAuthenticated"/>
  <Register v-if="!loginRoute && registerRoute && !isAuthenticated" />
  <ChangePassword v-if="!loginRoute && changepasswordRoute && isAuthenticated" />
  <ResetPassword v-if="!loginRoute && resetPasswordRoute && !isAuthenticated" />
  
</template>

<script>
import store from '../src/store';
import AdminPanel from '@/components/AdminPanel.vue'
import Login from '@/components/Login.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import Register from '@/components/Register.vue'
import ChangePassword from '@/components/ChangePassword.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import axios from 'axios'

export default {
   components: {AdminPanel,Login,ForgotPassword,Register,ChangePassword,ResetPassword},
   computed: {
    forgotPasswordRoute () {
      return this.$route && this.$route.name && (
        this.$route.name.indexOf('forgot-password') > -1
      )
    },
   
    loginRoute () {
      
      return this.$route && this.$route.name && (
        this.$route.name.indexOf('login') > -1 
      )
    },
    registerRoute () {
      return this.$route && this.$route.name && (
        this.$route.name.indexOf('register') > -1
      )
    },
    changepasswordRoute () {
      return this.$route && this.$route.name && (
        this.$route.name.indexOf('change-password') > -1
      )
    },
    resetPasswordRoute () {
      return this.$route && this.$route.name && (
        this.$route.name.indexOf('ResetPassword') > -1
      )
    },
    isAuthenticated () {
      return store.getters.isAuthenticated
    }
   },
   created() {
    let token = store.getters.oauthTokens
    if(token && window.location.pathname ==='/login' || window.location.pathname ==='/'){
      this.$router.push({ path: "/home" });
    }
    
    if (token) {
      axios.interceptors.request.use(function (config) {
        if (token) {
          // console.log(JSON.parse(token).access_token)
          // config.headers.authorization = 'Bearer ' + JSON.parse(token).access_token
          config.headers.authorization = 'Bearer ' + token
          config.headers.accept = 'application/json'
        }
        return config
      })
      
    }
   },
}
</script>