<template>
  <div class="login-page">

    <div class="login-wrap">
      <div class="row justify-content-center m-0">
        <div class="col-xl-6 col-lg-6 col-md-12 bg-color-6  ">
          <div class="fxt-header">
            <a href="#" class="fxt-logo"><img src="static/images/eluminous-pvt-ltd_icon.png" alt="" /></a>
            <div class="fxt-page-switcher">

              <router-link class="login-link" to="/login">
                <a href="#" class="switcher-text1 active">Log In</a>
              </router-link>

              <router-link class="" to="/register">
                <a href="#" class="switcher-text1">Register</a>
              </router-link>
            </div>
          </div>
          <div class="login-box bg-white border-radius-10">


            <div class="login-title">
              <h2 class="text-primary">Forgot Password</h2>
              <p> Enter your email address to reset your password</p>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-group custom custom-icon">
                <Field type="text" name="email" v-model="form.email" class="form-control form-control-lg"
                  :class="{ 'is-invalid': errors.email }" placeholder="Email" />
                <img src="static/images/email.png" alt="" />
                <div class="input-group-append custom">
                  <span class="input-group-text"><i class="icon-copy dw dw-user1"></i></span>
                </div>
                <div class="invalid-feedback">{{ errors.email }}</div>
                <div v-if="email_err" class="invalid-feedback-custom">{{ email_err }}</div>
              </div>

              <div class="row pb-30">
                <!-- <div class="col-6">
                              <div class="custom-control custom-checkbox">
                                                      <input type="checkbox" class="custom-control-input" id="customCheck1">
                                                      <label class="custom-control-label" for="customCheck1">Remember</label>
                                                  </div> 
                              </div>-->
                <div class="submit-button">
                  <div class="input-group mb-0">
                    <input class="btn btn-primary btn-lg btn-block" type="submit" value="submit" />
                  </div>


                </div>
              </div>
              <div class="inline_loader" v-if="isDisable">
                <img src="../../public/static/images/Spinner-1s-200px.gif" class="btn-loader">
              </div>
            </Form>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="hero-heading">
            <div class="headline">
              <h3>Welcome to Skill Upgradation Program</h3>
              <p>We are delighted to see you here. Get your way to upskill the technologies in the Digital World & Stand
                out from the Crowd</p>
            </div>
          </div>
          <div class="login-img">
            <img src="../../public/static/images/colleagues-working-project-discussing-details.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '../config/app'
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import userAPI from "../api/user";
import store from "../store";

export default {
  name: "forgot-password",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });
    return {
      schema,
      isDisable: false,
      form: {
        email: "",
      },
      email_err:'',
    };
  },
  methods: {
    onSubmit() {
      this.isDisable = true
      
      userAPI.forgotPassword(this.form).then((response) => {

        if (response.status === "success") {
          this.isDisable = false
          this.$toast.show(response.message, {
            type: "success",
          });
          this.$router.push({ name: "login" });
        } else {
          this.isDisable = false
          // this.$toast.show(response.message, {
          //   type: "error",
          // });
          this.email_err = 'User with this email address does not exists'
        }
      });
    },
  },
  created() {

  },
};
</script>
