import { createRouter, createWebHistory } from 'vue-router'
import AdminRouts from './admin'
import UserRouts from './user'

import store from '../store'

const AdminHome = () => import('@/components/admin/Home')
const UserLogin = () => import('@/components/Login')
const ForgotPassword = () => import('@/components/ForgotPassword')
const Register = () => import('@/components/Register')
const ResetPassword = () => import('@/components/ResetPassword')

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: AdminRouts.concat( UserRouts,[
    {
      path: '/login',
        name: 'login',
        meta: (route) => ({title: 'Log In'}),
        component: UserLogin
    },
  
    {
      path: '/forgot-password',
        name: 'forgot-password',
        meta: (route) => ({title: 'Forgot Password'}),
        component: ForgotPassword,
        beforeEnter (to, from, next) {
          if (!store.state.isAuthenticated) {
            next()
          } 
        }
    },
    {
      path: '/register',
        name: 'register',
        meta: (route) => ({title: 'Register'}),
        component: Register,
        beforeEnter (to, from, next) {
          if (!store.state.isAuthenticated) {
            next()
          } 
        }
    },
    {
      path: '/reset-password/:token',
        name: 'ResetPassword',
        meta: (route) => ({title: 'Reset Password'}),
        component: ResetPassword
       
    },
    
  ]),
})

router.afterEach((toRoute, fromRoute) => {
  // window.document.title = 'Buy1Give1 | ' + toRoute.name.toUpperCase()
  window.document.title = (toRoute.meta.length > 0 && toRoute.meta().title) ? toRoute.meta().title : toRoute.name.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
})

export default router
