<template>
  <div class="left-side-bar">
    <div class="brand-logo">
      <router-link :to="{ name: 'adminHome' }"> 
        <!-- <img src="/static/images/deskapp-logo.svg" alt="" class="dark-logo" /> -->
        <img
          src="/static/images/eluminous-pvt-ltd_white.png"
          alt=""
          class="dark-logo"
        />
        <!-- <img
          src="/static/images/deskapp-logo-white.svg"
          alt=""
          class="light-logo"
        /> -->
        <img
          src="/static/images/eluminous-pvt-ltd_white.png"
          alt=""
          class="light-logo"
        />
      </router-link>
      
      <div class="close-sidebar" data-toggle="left-sidebar-close">
        <i class="ion-close-round"></i>
      </div>
    </div>
    <div class="menu-block customscroll">
      <div class="sidebar-menu">
        <ul id="accordion-menu">
        <li class="dropdown" v-if="userRole == 4">
          <router-link :to="{ name: 'PlanListing' }" class="dropdown-toggle">
              <span class="micon fa fa-cogs"></span
              ><span class="mtext">Plan Listing</span></router-link>
          </li>
          <li class="dropdown" v-if="userRole == 1">
            <a href="javascript:;" class="dropdown-toggle">
              <span class="micon fa fa-user"></span
              ><span class="mtext">Manage User</span>
            </a>
            <ul class="submenu">
              <li>
                <router-link :to="{ name: 'UserListing' }"
                  >User Listing</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'adminAddUser' }"
                  >Add User</router-link
                >
              </li>
            </ul>
          </li>
          <li class="dropdown" v-if="userRole == 1">
            <a href="javascript:;" class="dropdown-toggle">
              <span class="micon fa fa-cogs"></span
              ><span class="mtext">Skill Plan</span>
            </a>
            <ul class="submenu">
              <li>
                <router-link :to="{ name: 'skillList' }"
                  >Skill Plan Listing</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'addSkillPlan' }"
                  >Add Skill Plan</router-link
                >
              </li>
            </ul>
          </li>
          <li class="dropdown" v-if="userRole == 3">
          <router-link :to="{ name: 'PlanListing' }" class="dropdown-toggle">
              <span class="micon dw dw-house-1"></span
              ><span class="mtext">My Plans</span></router-link>
          </li>
          <li class="dropdown" v-if="userRole == 3">
            <a href="javascript:;" class="dropdown-toggle">
              <span class="micon fa fa-users" aria-hidden="true"></span
              ><span class="mtext">Team Plan</span>
            </a>
            <ul class="submenu">
              <li>
                <router-link :to="{ name: 'technical-growth-list' }"
                  >Plan Listing</router-link
                >
              </li>
            </ul>
          </li>
          <li class="dropdown" v-if="userRole == 3">
            <a href="javascript:;" class="dropdown-toggle">
              <span class="micon dw dw-library"></span
              ><span class="mtext">Review & Rating Listing</span>
            </a>
            <ul class="submenu">
              <li>
                <router-link :to="{ name: 'teamLeaderreviewRatingList' }"
                  >Review & Rating </router-link
                >
              </li>
            </ul>
          </li>
          <li class="dropdown" v-if="userRole == 1">
            <a href="javascript:;" class="dropdown-toggle">
              <span class="micon dw dw-library"></span
              ><span class="mtext">Review & Rating Listing</span>
            </a>
            <ul class="submenu">
              <li>
                <router-link :to="{ name: 'reviewRatingList' }"
                  >Review & Rating </router-link
                >
              </li>
              <!-- <li>
                <router-link :to="{ name: 'addRatingRemark' }"
                  >Add Rating & Remark</router-link
                >
              </li> -->
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import store from '../../../store'
export default {
  name: "LeftSide",
  computed: {
    userRole () {
        var role = null
        if (this.userLocalData !== null) {
            role = this.userLocalData.role_id
        }
        return role
    }
  },
  data() {
    return {
      userLocalData:store.getters.currentUser.id ? store.getters.currentUser : JSON.parse(window.atob(store.getters.currentUser))
    };
  },
};
</script>
