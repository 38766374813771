<template>
  <div class="login-page">
    <div class="login-wrap">
      <div class="row justify-content-center m-0">
        <div class="col-xl-6 col-lg-6 col-md-12 bg-color-6  ">
          <div class="fxt-header">
            <a href="#" class="fxt-logo"><img src="static/images/eluminous-pvt-ltd_icon.png" alt="" /></a>
            <div class="fxt-page-switcher">

              <router-link class="login-link" to="/login">
                <a href="#" class="switcher-text1 active">LogIn</a>
              </router-link>

              <router-link class="" to="/register">
                <a href="#" class="switcher-text1">Register</a>
              </router-link>
            </div>
          </div>
          <div class="login-box bg-white border-radius-10">
            <div class="login-title">
              <h2 class="text-primary">Login</h2>
              <p>Login to continue in our SkillApp</p>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            
              <div class="input-group custom custom-icon">
                <Field type="text" name="email" v-model="form.email" class="form-control form-control-lg"
                  :class="{ 'is-invalid': errors.email }" placeholder="Email" />
                <img src="static/images/email.png" alt="" />
                <div class="input-group-append custom">
                  <span class="input-group-text"><i class="icon-copy dw dw-user1"></i></span>
                </div>
                <div class="invalid-feedback">{{ errors.email }}</div>
              </div>
              <div class="input-group custom custom-icon custom_password">
                <div class="control is-expanded">
                  <Field :type="showPassword ? 'text':'password'" name="password" v-model="form.password" class="form-control form-control-lg"
                    :class="{ 'is-invalid': errors.password }" placeholder="password" />
                  <!-- <Field v-if="!showPassword" type="password" name="password" v-model="form.password" class="form-control form-control-lg"
                  :class="{ 'is-invalid': errors.password }" placeholder="password" /> -->
                  <div class="invalid-feedback">{{ errors.password }}</div>
                </div>
                <div class="control icon_last">
                  <button class="button" type="button" @click="toggleShow"><span class="icon is-small is-right">
                    <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </span>
                  </button>
                </div>
              </div>
              
              <div class="row pb-30">
              <div class="submit-button">
                  <div class="input-group mb-">
                    <button class="btn btn-primary btn-lg btn-block" type="submit" value="Sign In">
                      Sign In
                    </button>
                    
                    <div class="inline_loader" v-if="isDisable">
                    <img src="/static/images/Spinner-1s-200px.gif" class="btn-loader">
                  </div>
                  </div>
                  <div class="forgot-password text-center have-account">
                    <router-link to="/forgot-password"><span>Forgot Password ?</span></router-link>
                  </div>
              </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="input-group mb-0 have-account">
                    <router-link class="" to="/register">Don't have an account? <span>Register</span></router-link>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="hero-heading">
            <div class="headline">
              <h3>Welcome to Skill Upgradation Program</h3>
              <p>We are delighted to see you here. Get your way to upskill the technologies in the Digital World & Stand
                out from the Crowd</p>
            </div>
          </div>
          <div class="login-img">
            <img src="static/images/colleagues-working-project-discussing-details.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '../config/app'
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import userAPI from "../api/user";
import store from "../store";
export default {
  name: "login",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
    return {
      schema,
      isDisable: false,
      form: {
        email: "",
        password: "",
      },
      bUrl: config.server,
      showPassword:false
    };
  },
  methods: {
    onSubmit() {
      this.isDisable = true
      this.$store
        .dispatch("login", this.form)
        .then((response) => {
          if (response.status === "success") {
            this.isDisable = false
            this.$toast.show('User logged in successfully', {
              type: "success",
            });
            if (process.env.NODE_ENV === 'production') {
              window.location.href = this.bUrl + '/home';
            } else {
              window.location.href = 'http://localhost:8080/home';
            }

            // this.$router.push({ name: "adminHome" });
          } else {
            this.isDisable = false

            this.$toast.show(response.message, {
              type: "error",
            });
           
          }
        })
        .catch((err) => { });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  created() {

  },
};
</script>