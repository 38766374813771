<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-light p-0">
      <div class="container-fluid">
        <div class="brand-logo">
          <router-link :to="{ name: 'adminHome' }">
            <!-- <img src="/static/images/eluminous-pvt-ltd_black.png" alt="" class="dark-logo" />
            <img src="/static/images/eluminous-pvt-ltd_black.png" alt="" class="light-logo" /> -->
            <img src="/static/images/eluminous-pvt-ltd.png" alt="" class="dark-logo" />
            <img src="/static/images/eluminous-pvt-ltd.png" alt="" class="light-logo" />
          </router-link>
        </div>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="sidebar-menu">
            <ul id="accordion-menu">
              <li class="dropdown no_dropdown_icon" v-if="userRole == 4">
                <router-link :to="{ name: 'PlanListing' }" class="dropdown-toggle">
                  <span class="micon fa fa-list-alt"></span><span class="mtext">Plan Listing</span></router-link>
              </li>
              <li class="dropdown" v-if="userRole == 1">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-user"></span><span class="mtext">Manage User</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'UserListing' }"> User Listing</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'adminAddUser' }"> Add User</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'skill-set-user-list' }"> Skill Set User List</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown" v-if="userRole == 4">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-file-code-o"></span><span class="mtext"> Manage Audit</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item d-block">
                        <router-link :to="{ name: 'add-audit' }">

                          Add Audit
                        </router-link>
                      </li>


                      <li class="dropdown-item d-block">
                        <router-link :to="{ name: 'audit-list' }">

                          Audit List
                        </router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'tl-audit-list' }"> Teams Audit</router-link>
                      </li>

                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown" v-if="userRole == 1">
                <div class="dropdown">
                    <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                      <span class="micon fa fa-file-code-o"></span><span class="mtext"> Manage Audit</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                      <ul>
                        <li class="dropdown-item d-block">
                          <router-link :to="{ name: 'all-audit-list' }">
                            <span class="mtext">Audit List</span>
                          </router-link>
                        </li>
                        <li class="dropdown-item d-block">
                          <router-link :to="{ name: 'quarterly-audit-report' }">
                            Quarterly Audit Report
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
              </li>

              <li class="dropdown" v-if="userRole == 1">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-user-circle-o"></span><span class="mtext">Manage Client</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'add-client' }"> Add Client</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'client-list' }"> Client List</router-link>
                      </li>

                    </ul>
                  </div>
                </div>
              </li>

              <li class="dropdown" v-if="userRole == 1">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-cogs"></span><span class="mtext">Manage Project</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'add-project' }"> Add Project</router-link>
                      </li>

                      <li class="dropdown-item">
                        <router-link :to="{ name: 'project-list' }"> Project List</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li class="dropdown" v-if="userRole == 1">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-file-text"></span><span class="mtext">Skill Plan</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'skillList' }"> Skill Plan Listing</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'addSkillPlan' }"> Add Skill Plan</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown" v-if="userRole == 3">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-file-text"></span><span class="mtext">Manage Plan</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'PlanListing' }"> My Plans</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'technical-growth-list' }"> Team Plan</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li class="dropdown" v-if="userRole == 3">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-file-code-o"></span><span class="mtext">Manage Audit</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'add-audit' }"> Add Audit</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'audit-list' }">My Audit </router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'tl-audit-list' }"> Teams Audit</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li class="dropdown" v-if="userRole == 3">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-cogs"></span><span class="mtext">Manage Project</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'add-project' }"> Add Project</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'project-list' }">Project List</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li class="dropdown" v-if="userRole == 3">
                <div class="dropdown">
                  <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
                    <span class="micon fa fa-user-circle-o"></span><span class="mtext">Manage Client</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                    <ul>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'add-client' }"> Add Client</router-link>
                      </li>
                      <li class="dropdown-item">
                        <router-link :to="{ name: 'client-list' }"> Client List</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>



              <li class="dropdown no_dropdown_icon" v-if="userRole == 3">
                <router-link :to="{ name: 'teamLeaderreviewRatingList' }" class="dropdown-toggle">
                  <span class="micon fa fa-list-alt"></span><span class="mtext">TG Rating</span>
                </router-link>
              </li>
              <li class="dropdown no_dropdown_icon" v-if="userRole == 1">
                <router-link :to="{ name: 'reviewRatingList' }" class="dropdown-toggle">
                  <span class="micon fa fa-list-alt"></span><span class="mtext">TG Rating</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="header-right">
      <div class="user-info-dropdown">
        <div class="dropdown">
          <a class="dropdown-toggle" href="javascript:;" role="button" data-toggle="dropdown">
            <span class="user-icon">
              <img :src="profilePhoto" v-if="profilePhoto != ''" id="huser_image"
                @error="onImageLoadFailure($event, 'huser_image')">
              <img src="/static/images/chat-img2.jpg" alt="" v-else>
            </span>
            <span class="user-name">{{ userName }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
            <a class="dropdown-item" @click="goProfile()" href="javascript:;"><i class="dw dw-user1"></i> Profile</a>
            <!-- <a class="dropdown-item" href="javascript:;"><i class="dw dw-settings2"></i> Setting</a>
                        <a class="dropdown-item" href="javascript:;"><i class="dw dw-help"></i> Help</a> -->
            <div class="dropdown-item">
              <router-link to="/change-password"><i class="dw dw-password"></i>Change Password</router-link>
            </div>
            <div class="dropdown-item" v-if="userData.role_id !=1">
              <router-link to="/admin/skill-set-list"><i class="fa fa-cogs"></i>Manage Skill Set</router-link>
            </div>
            <div class="dropdown-item" v-if="userData.role_id ==1">
              <router-link to="/admin/setting"><i class="fa fa-sliders"></i>Settings</router-link>
            </div>
            <a class="dropdown-item" @click="logout()" href="javascript:;"><i class="dw dw-logout"></i> Log Out</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../config/app'
import store from "../../store";
import { useStore } from "vuex";
export default {
  name: "adminHeader",
  computed: {
    userName() {
      var name

      if (this.userData !== null) {
        name = this.userData.first_name + ' ' + this.userData.last_name
      }
      return name
    },
    userRole() {
      var role = null
      if (this.userData !== null) {
        role = this.userData.role_id
      }
      return role
    },
    profilePhoto() {
      var profile_photo_path
      if (this.userData !== null) {
        profile_photo_path = this.userData.profile_photo_path
      }
      return this.bUrl + profile_photo_path
    }
  },
  data() {
    return {
      isAdminLogin: false,
      bUrl: config.server,
      userData: store.getters.currentUser.id ? store.getters.currentUser : JSON.parse(window.atob(store.getters.currentUser))
    };
  },
  methods: {
    onImageLoadFailure(event, id) {
      document.getElementById(id).src = '/static/images/chat-img2.jpg'
    },
    logout() {
      //   if (localStorage.getItem('login_user_type')) {
      //     localStorage.removeItem('login_user_type')
      //     localStorage.removeItem('login_user_name')
      //     localStorage.removeItem('login_user_password')
      //   }
      this.$store.dispatch("logout");

      // this.$toast.show("Logout Sucessfully!", {
      //   type: "success",
      // });
      if (process.env.NODE_ENV === 'production') {
        window.location = this.bUrl + '/login';
      } else {
        window.location = 'http://localhost:8080/login';
      }
      //   this.$router.push({ name: "login" });
    },
    goProfile() {
      this.$router.push({
        path: "/admin/update-user",
        // query: { user_id: store.getters.currentUser.id },
      });
    },
  },
  created() {

  },
};
</script>
<style scoped>
li .dropdown-item {
  background: #3f51b5;
  border-bottom: none !important;
  width: auto;
  padding: 10px;
}

li .dropdown-item a {
  color: white !important;
}
</style>
