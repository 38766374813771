const AdminHome = () => import("@/components/admin/Home");
const AddUser = () => import("@/components/user/AddUser");
const SkillList = () => import("@/components/admin/SkillList");
const SkillPlan = () => import("@/components/admin/AddSkillPlan");
const ReviewRatingList = () => import("@/components/user/ReviewRatingList");
const AddRatingRemark = () => import("@/components/user/AddRatingRemark");
const UserListing = () => import("@/components/user/UserListing");
const PlanListing = () => import("@/components/user/PlanListing");

const TeamLeadReviewRatingList = () =>
  import("@/components/team_leader/ReviewRatingList");
const AddTechnicalGrowth = () =>
  import("@/components/team_leader/AddTechnicalGrowth");
const TechnicalGrowthList = () => import("@/components/team_leader/MyTeamList");
const TeamPlanDetails = () =>
  import("@/components/team_leader/TeamPlanDetails");
const AdminTeamPlanDetails = () =>
  import("@/components/admin/AdminTeamPlanDetails");
const UpdateUser = () => import("@/components/user/UpdateUser");
const UpdateSkillPlan = () => import("@/components/admin/UpdateSkillPlan");
const AddAudit = () => import("@/components/user/AddAudit");
const EditClient = () => import("@/components/user/EditClient");
const AuditList = () => import("@/components/user/AuditList");
const AllAuditList = () => import("@/components/user/AllAuditList");
const TlAuditList = () => import("@/components/user/TlAuditList");
const EditAudit = () => import("@/components/user/EditAudit");
const ViewAudit = () => import("@/components/user/ViewAudit");
const ClientList = () => import("@/components/admin/ClientList");
const AddClient = () => import("@/components/admin/AddClient");
const AddProject=() => import("@/components/admin/AddProject");
const ProjectList = () => import("@/components/admin/ProjectList");
const ChangePassword = () => import("@/components/ChangePassword");
const addFinalRating = () => import("@/components/admin/addFinalRating");
const adminaddFinalRating = () => import("@/components/admin/adminaddFinalRating");
const addTlReview = () => import("@/components/admin/addTlReview");
const QuarterlyAuditReport = () => import("@/components/admin/QuarterlyAuditReport");
const TlResourceList = () => import("@/components/admin/TlResourceList");
const TlAdminResourceList = () => import("@/components/admin/TladminResourceList");
const UserSkillList = () => import("@/components/admin/UserSkillList");
const PageNotFound = () => import("@/components/PageNotFound.vue");
const UserTechnologyList = () => import("@/components/user/UserTechnologyList");
const skillSetUserList = () => import("@/components/admin/skillSetUserList");
const Setting = () => import("@/components/admin/Setting");


import store from '../store'
let userData = null
if(store.getters.currentUser){
 userData = store.getters.currentUser.id ? store.getters.currentUser : JSON.parse(window.atob(store.getters.currentUser))
}
export default [
  
  {
    path: '/home',
      name: 'adminHome',
      components: {
        admin: AdminHome,
      },
      alias: '/'
  },
  {
    path: "/admin/add-user",
    name: "adminAddUser",
    components: {
      admin: AddUser,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/update-user",
    name: "updateUser",
    components: {
      admin: UpdateUser,
    },
  },
  {
    path: "/admin/skill-list",
    name: "skillList",
    components: {
      admin: SkillList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/add-skill-plan",
    name: "addSkillPlan",
    components: {
      admin: SkillPlan,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/update-skill-plan",
    name: "updateSkillPlan",
    components: {
      admin: UpdateSkillPlan,
    },
  },
  {
    path: "/admin/tg-rating-list",
    name: "reviewRatingList",
    components: {
      admin: ReviewRatingList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/add-rating-remark",
    name: "addRatingRemark",
    components: {
      admin: AddRatingRemark,
    },
  },
  {
    path: "/admin/add-rating-remark",
    name: "UpdateRatingRemark",
    components: {
      admin: AddRatingRemark,
    },
  },
  {
    path: "/admin/add-user-listing",
    name: "UserListing",
    components: {
      admin: UserListing,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/plan-listing",
    name: "PlanListing",
    components: {
      admin: PlanListing,
    },
  },
 
  {
    path: "/team-leader/tg-rating-list",
    name: "teamLeaderreviewRatingList",
    components: {
      admin: TeamLeadReviewRatingList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 3) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/team-leader/add-technical-growth",
    name: "add-technical-growth",
    components: {
      admin: AddTechnicalGrowth,
    },
  },
  {
    path: "/team-leader/team-list",
    name: "technical-growth-list",
    components: {
      admin: TechnicalGrowthList,
    },
  },
  {
    path: "/team-leader/team-plan-details",
    name: "team-plan-details",
    components: {
      admin: TeamPlanDetails,
    },
  },
  {
    path: "/admin/team-plan-details",
    name: "admin-team-plan-details",
    components: {
      admin: AdminTeamPlanDetails,
    },
  },
  {
    path: "/admin/add-Audit",
    name: "add-audit",
    components: {
      admin: AddAudit,
    },
  }, 
  {
    path: "/admin/edit-audit",
    name: "edit-audit",
    components: {
      admin: EditAudit,
    },
  },
  {
    path: "/admin/view-audit",
    name: "view-audit",
    components: {
      admin: ViewAudit,
    },
  },
  {
    path: "/admin/audit-list",
    name: "audit-list",
    components: {
      admin: AuditList,
    },
  },
  {
    path: "/admin/all-audit-list",
    name: "all-audit-list",
    components: {
      admin: AllAuditList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/tl-audit-list",
    name: "tl-audit-list",
    components: {
      admin: TlAuditList,
    },
  },
  {
    path: "/admin/client-list",
    name: "client-list",
    components: {
      admin: ClientList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id <= 3 ) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/add-client",
    name: "add-client",
    components: {
      admin: AddClient,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id <= 3 ) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/edit-client/:id",
    name: "edit-client",
    components: {
      admin: EditClient,
    },
  },
  {
    path: "/admin/add-project",
    name: "add-project",
    components: {
      admin: AddProject,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id <= 3 ) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/project-list",
    name: "project-list",
    components: {
      admin: ProjectList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id <= 3 ) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/change-password",
    name: "change-password",
    components: {
      admin: ChangePassword,
    },
  },
  {
    path: "/admin/add-final-rating",
    name: "add-final-rating",
    components: {
      admin: addFinalRating,
    },
  },
  {
    path: "/admin/add-admin-final-rating",
    name: "add-admin-final-rating",
    components: {
      admin: adminaddFinalRating,
    },
  },
  {
    path: "/admin/add-tl-review",
    name: "add-tl-review",
    components: {
      admin: addTlReview,
    },
  },
  {
    path: "/admin/quarterly-audit-report",
    name: "quarterly-audit-report",
    components: {
      admin: QuarterlyAuditReport,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/tl-resource-list",
    name: "team-leader-resource",
    components: {
      admin: TlResourceList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/tl-admin-resource-list",
    name: "team-admin-leader-resource",
    components: {
      admin: TlAdminResourceList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/user-skill-list",
    name: "user-skill-list",
    components: {
      admin: UserSkillList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  },
  {
    path: "/admin/skill-set-list",
    name: "skill-set-list",
    components: {
      admin: UserTechnologyList,
    },
  },
  {
    path: "/admin/skill-set-user-list",
    name: "skill-set-user-list",
    components: {
      admin: skillSetUserList,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  }, 
  {
    path: "/admin/setting",
    name: "setting",
    components: {
      admin: Setting,
    },
    beforeEnter (to, from, next) {
      if (userData.role_id == 1 || userData.role_id == 2) {
        next()
      }else{
        next('/page-not-found')
      }
    }
  }, 
  {
    path: "/page-not-found",
    name: "page-not-found",
    components: {
      admin: PageNotFound,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "page-not-found",
    components: {
      admin: PageNotFound,
    },
  },
  
];
