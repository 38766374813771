<template>
<div class="pd-ltr-20">
    <div class="footer-wrap pd-20 mb-20 card-box">
        eLuminous Technologies <i class="fa fa-copyright" aria-hidden="true"></i> {{ new Date().getFullYear() }} All Rights Reserved
    </div>
	</div>
</template>
<script>
export default {
  name: 'adminFooter',
}
</script>